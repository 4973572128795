/**
 * Main app js file
 *
 * @copyright Copyright Maneko
 * @author    Maneko
*/
jQuery(document).ready(function ($) {
    'use strict';

    var resizeTimer,
        $window = $(window),
        $dotdotdot = $('.dotdotdot'),
        $select2 = $('.select2'),
        $select2Container = $('.select2-wrapper'),
        $rowEqHeight = $('.row-eq-height'),
        $townSelect = $('#town-select'),
        $zoneSelect = $('#zone-select'),
        $searchBtn = $('#search-btn'),
        $searchInput =  $searchBtn.siblings('input[type="text"]'),
        $navbarLeft = $('.navbar-left'),
        $modal = $('.modal'),
        $ajaxForm = $('.ajax'),
        select2config = {
            allowClear: true,
            closeOnSelect: false,
            dropdownParent: $select2Container, // Need for iPad bug
            minimumResultsForSearch: -1,
            language: {
                noResults: function () {
                    return Living.no_results_found;
                },
            },
        },
        fieldRequired = $('meta[name="required-field"]').attr('content'),
        fieldOneRequired = $('meta[name="phone-or-mail-required"]').attr('content'),
        validPhone = $('meta[name="valid-phone"]').attr('content'),
        validEmail = $('meta[name="valid-email"]').attr('content'),
        validPhoneEmail = $('meta[name="phone-or-mail-required"]').attr('content'),
        validCP = $('meta[name="valid-cp"]').attr('content'),
        validCheck = $('meta[name="valid-check"]').attr('content'),
        beginning = Living.beginning;

        // console.log("beginning: " + beginning);
        // if (beginning == true) {
        //     $("select.select2").select2({ allowClear: true });
        // }
        //if (true) {}

    /**
     * Initialize Select2.
     *
     * @return void
     */
    function initSelect2()
    {
        $select2.select2(select2config);
    }

    /**
     * Initialize Select2 count number if multiple.
     *
     * @return void
     */
    function initSelect2CountNumber()
    {
        $select2.each(function() {
            updateSelect2($(this));
        });
    }

    /**
     * Initialize Dotdotdot.
     *
     * @return void
     */
    function initDotdotdot()
    {
        $dotdotdot.dotdotdot();
    }

    /**
     * Initialize Sqiper.
     *
     * @return void
     */
    function initSwiper()
    {
        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            grabCursor: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    /**
     * Initialize Row Eq Height Grid.
     *
     * @return void
     */
    function initRowEqHeight()
    {
        $rowEqHeight.each(function () {
            var heights = $(this).find('.col-eq-height').map(function() {
                return $(this).outerHeight();
            }).get(), maxHeight = Math.max.apply(null, heights);

            $(this).find('.col-eq-height').outerHeight(maxHeight);
        });
    }

    /**
     * Reset Row Eq Height Grid.
     *
     * @return void
     */
    function resetRowEqHeight()
    {
        $rowEqHeight.find('.col-eq-height').css('height', 'auto');
    }

    /**
     * Select2 is multiple?
     *
     * @param  object $el
     * @return bool
     */
    function select2isMultiple($el)
    {
        if (typeof $el.attr('multiple') !== 'undefined') {
            return true;
        }
        return false;
    }

    /**
     * Element exists?
     *
     * @param  object $el
     * @return bool
     */
    function elementExists($el)
    {
        if ($el.length > 0) {
            return true;
        }
        return false;
    }

    /**
     * Update Select2.
     *
     * @param  object $el
     * @return void
     */
    function updateSelect2($el)
    {
        var $container = $el.siblings('span.select2'),
            $count = $container.find('.count'),
            $ul = $container.find('ul'),
            $search = $ul.find('.select2-search'),
            selected = $ul.find('li').length - 1,
            selectedZones = $("#zone-select").val();

            //console.log(selected);

        if (select2isMultiple($el)) {
            if (selected <= 1 ) {
                $count.remove();
                $search.show();
                // console.log('selected 1');
                /*if($.inArray('allzones',selectedZones)){
                    $('#zone-select .select2-search').show().html($('#zone-select').data('allzones'));
                    console.log(selectedZones+' x ');
                } else {
                    $search.show();
                    console.log($ul.find('li'));
                }*/
            }
            else {
                $search.hide();
                if(selected > 1){
                    selected = selected - 1;
                    if (elementExists($count)) {
                        $count.html(selected);
                    } else {
                        $container.prepend('<span class="count">' + selected +'</span>');
                    }
                } else {
                    $count.remove();
                }
            }
        }

        $('li[id$="deselect"]').click(function(){
            console.log('deselect');
        })
        $("#property-select").on("select2:select", function(e) {

            var selected = $("#property-select").val();
            console.log(selected);

            if($.inArray('deselect',selected) >= 0){
                $("#property-select").val('[]').trigger('change').select2('close');
            }

        });
    }


    /**
     * On Select2 closed.
     *
     * @return void
     */
    function onSelect2Close()
    {
        updateSelect2($(this));
    }

    /**
     * Set zone value
     *
     * @param  array location
     * @return string
     */
    function setZone(location)
    {
        var zone = '19408_______________';

        if (location['barri']) {
            zone = '19408__' +
                   ('0' + location['mu']).slice(-3) +
                   '______' +
                   ('0' + location['dis']).slice(-2) +
                   ('0' + location['ba']).slice(-2);
        }
        else if (location['dist']) {
            zone = '19408__' +
                   ('0' + location['mu']).slice(-3) +
                   '______' +
                   ('0' + location['dis']).slice(-2) +
                   '__'
        }

        return zone;
    }

    /**
     * Clean zone Select2 data when town
     * cross is clicked.
     *
     * @return void
     */
    function cleanZoneData ()
    {
        $zoneSelect.html('').select2(select2config);
        $zoneSelect.html('<option></option>');
    }

    /**
     * Init Select2 zones if town was selected
     * and stored in session data.
     *
     * @return void
     */
    function initSelect2Zones()
    {
        var zones = Living.zones;
        //console.log("living" + JSON.stringify(Living));
        // firstly, paint the options
        onTownSelected();

        // secondly, select the stored zones
        if (zones) {
            for (var i = 0, length = zones.length; i < length; i++) {
                $zoneSelect.find('option[value="' + zones[i] + '"]').attr('selected','selected');
            }
        }

    }

    /**
     * On town selected.
     *
     * @return void
     */
    function onTownSelected()
    {
        var html = '',
            district = '',
            lastDistric = '',
            group = true,
            locations = Living.locations,
            location = $townSelect.val(),
            $select = $townSelect.closest('select');

        // console.log("location: " + location);
        cleanZoneData();


        html = '<option></option><option id="allzones" value="allzones">'+$('#zone-select').data('allzones')+'</option>';

        // Look over locations
        for (var i = 0, length = locations.length; i < length; i++) {
            // Get the locations equal to the selected
            if (locations[i]['mu'] == location && locations[i]['dist'] != '') {
                if (locations[i]['barri'] == '') {
                    html += '<option value="' + setZone(locations[i]) + '">' + locations[i]['dist'] +'</option>';
                } else {
                    district = locations[i]['dist'];
                    group = true;
                    if (district !=  lastDistric) {
                        for (var j = 0, length = locations.length; j < length; j++) {
                            // Get the locations equal to the district
                            if (locations[j]['dist'] == district) {
                                //If location has neighborhood make a group
                                if (group) {
                                    html += '<optgroup label="' + district + '">';
                                    group = false;
                                }
                                html += '<option value="' + setZone(locations[j]) + '">' + locations[j]['barri'] +'</option>';
                            }
                        }
                        lastDistric = district;
                        // If location has neighborhood close the group
                        html += '</optgroup>';
                    }
                }
            }
        }

        $zoneSelect.html(html);


        $("#zone-select").on("select2:select", function(e) {

            var selected = $("#zone-select").val();
            console.log("zone-selected: " + selected);
            if($.inArray('allzones',selected) >= 0){
                $("#zone-select").val('[]').trigger('change').select2('close').parent('.form-group').find('.select2-search__field').attr('placeholder',$('#zone-select').data('allzones'));
            }


            /*if(data['selected'] == true){
                var data = e.params.data;
                var optionId = data['_resultId'];
                if(optionId.match(/allzones$/) != null){
                    var selectedItems = [];
                    var allOptions = $("#zone-select option");
                    allOptions.each(function() {
                        selectedItems.push( $(this).val() );
                    });
                    $("#zone-select").select2("val", selectedItems);
                    $('#select2-zone-select-results li').attr('aria-selected',false);
                    $('#select2-zone-select-results > li:first-child').attr('aria-selected',true);
                }
            }*/
        });

    }



    $("#zone-select").on("select2:open", function(e) {
        $('body').find('.select2-container--open').find('.select2-results__option > strong').append('<span>All</span>');
        console.log('open');
        /*var curLength;
        if ($('select2 .select2-results__group').length!=curLength){
            curLength = $('select2 .select2-results__group').length;
            $('.select2-results__group').click(function(){
                console.log('group');
                $(this).next('ul').find('li').attr('aria-selected',true);
            })
        }*/
    });
    $("#zone-select").on("select2:select", function(e) {
        console.log('select');
    });
    $('.select2-container--open').click(function(){
        console.log('click');
    })
    /**
     * On resize has stopped.
     *
     * @return void
     */
    function onResizeHasStopped()
    {
        resetRowEqHeight();
        initRowEqHeight();
    }

    /**
     * On resize.
     *
     * @return void
     */
    function onResize()
    {
        if (isDesktop()) {
            $searchInput.hide();
        } else {
            $searchInput.show();
        }

        $navbarLeft.show();
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(onResizeHasStopped, 250);
    }

    /**
     * Is the web displaying in desktop mode.
     *
     * @return bool
     */
    function isDesktop()
    {
        if (ResponsiveBootstrapToolkit.is('>=md')) {
            return true;
        }
        return false;
    }

    /**
     * On search btn clicked.
     *
     * @return void
     */
    function onSearchBtnClicked()
    {
        var $form = $(this).closest('form'),
            val = $searchInput.val();

        if (val) {
            $form.submit();
        }

        if (isDesktop()) {;
            $(this).toggleClass('active');
            $navbarLeft.toggle();
            $searchInput.toggle();
        }
    }

    $.validator.addMethod("custom_email", function(value, element) {
        return this.optional(element) || value === "NA" ||
            value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }, "Por favor, introduzca un email válido.");

    $.validator.addMethod("custom_tel", function(value, element) {
        return this.optional(element) || value === "NA" ||
            value.match(/^[0-9+]+$/i);
    }, "Por favor, introduzca un téléfono válido.");


    /**
     * On form submit.
     *
     * @return void
     */
    function onFormSubmit(event)
    {
        var self = $(this),
            url = self.attr('action');

        var validator = self.validate({
            rules: {
                name: {
                    required: false
                },
                surname: {
                    required: false
                },
                company: {
                    required: false
                },
                address: {
                    required: false
                },
                postal_code: {
                    required: false,
                    minlength:5, 
                    maxlength:5
                },
                zone: {
                    required: false
                },
                property_type: {
                    required: false
                },
                property_location: {
                    required: false
                },
                max_price: {
                    required: false
                },
                proposed_price: {
                    required: false
                },
                phone: {
                  require_from_group: [1, ".required-group"],
                  minlength: 9,
                  custom_tel: true
                },
                email: {
                  require_from_group: [1, ".required-group"],
                  custom_email: true
                },
                chk_policy:{
                  required: true
                }

            },
            messages: {
              query: fieldRequired,
              name: fieldRequired,
              surname: fieldRequired,
              company: fieldRequired,
              address: fieldRequired,
              postal_code: fieldRequired,
              zone: fieldRequired,
              property_type: fieldRequired,
              max_price: fieldRequired,
              property_location: fieldRequired,
              proposed_price: fieldRequired,
              phone: validPhoneEmail,
              email: validPhoneEmail,
              custom_email: validEmail,
              postal_code: validCP,
              chk_policy:validCheck
            },
        });
        event.preventDefault();

        if(validator.form() == true){
            $('#sending').show();

            $.post(
                url,
                $(this).serialize(),
                function (result) {
                    if (result == 'true') {
                        self.next('.ajax-message').show();
                        self.hide();
                        // window.location.replace('gracias');
                        window.location.href = "gracias";
                    }
                }
            );
        }


    }

    /**
     * Form validity
     */
    $('.form-group input').on('change invalid', function() {
        var textfield = $(this).get(0);

        // 'setCustomValidity not only sets the message, but also marks
        // the field as invalid. In order to see whether the field really is
        // invalid, we have to remove the message first
        textfield.setCustomValidity('');

        if (!textfield.validity.valid) {
          textfield.setCustomValidity('Por favor, selecciona una de las opciones.');
        }
    });

    /**
     * On Modal show.
     *
     * @return void
     */
    function onModalShow()
    {
        $(this).find('form').show();
        $(this).find('.ajax-message').hide();
    }

    /**
     * On Video Modal show.
     */
    function onVideoModalShow()
    {
        var $iframe = $(this).find('iframe');
        var videoSrc = $iframe.data('src');
        $iframe.attr('src',videoSrc);
    }

    /**
     * On Video Modal hide.
     */
    function onVideoModalHide()
    {
        var $iframe = $(this).find('iframe');
        $iframe.attr('src','');
    }

    /**
     * Set events.
     *
     * @return void
     */
    function setEvents()
    {
        $select2.on('select2:close', onSelect2Close);

        $townSelect.on('select2:select', onTownSelected);
        $townSelect.on('select2:unselect', cleanZoneData);

        $searchBtn.on('click', onSearchBtnClicked);

        $modal.on('show.bs.modal', onModalShow);
        $('#videoModal').on('show.bs.modal', onVideoModalShow);
        $('#videoModal').on('hide.bs.modal', onVideoModalHide);
        $ajaxForm.submit(onFormSubmit);

        $window.on('resize', onResize);
    }

    /**
     * Initialize method.
     *
     * @return void
     */
    function init()
    {
        initSelect2();
        initSelect2Zones();
        setTimeout(function() {
            initSelect2CountNumber();
        }, 1000);

        initDotdotdot();

        initSwiper();

        initRowEqHeight();

        setEvents();
    }

    // Initialize
    init();
});
